import Pushy from 'pushy-sdk-web'
import UserRepository from '@/repositories/UserRepository'

class PushyManager {
    constructor(Vue, options) {
        this.app = Vue
        this.options = options
        if ('store' in options) this.$store = options.store

        this.is_registered = false
    }

    /** Retrieves the user's token. */
    register() {
        if (!this.is_registered) {
            if (this.$store.getters['pushy/isMobile']) {
                this.is_registered = true
                UserRepository.registerPushyToken(
                    this.$store.getters['pushy/token'],
                    this.$store.getters['pushy/platform'],
                    this.$store.getters['pushy/type']
                )
            } else {
                this.#generateToken()

                Pushy.setNotificationListener(
                    function () {
                        this.dispatchNotifications()
                    }.bind(this)
                )
            }
        }
    }

    /** Processing an incoming Pushy notification */
    dispatchNotifications() {
        this.$store.dispatch('notifications/getNotifications', this.$store.getters['user_connected/user_connected'].id_client)
    }

    /** Sends a request to retrieve the token. */
    #generateToken() {
        Pushy.register({ appId: this.retrieveAppId() })
            .then(
                function (deviceToken) {
                    this.is_registered = true
                    UserRepository.registerPushyToken(deviceToken, this.retrievePlatform(), 'web')
                }.bind(this)
            )
            .catch(function (error) {
                if (window.location.hostname !== 'localhost') {
                    console.error(error)
                }
            })
    }

    retrieveAppId() {
        switch (window.location.hostname) {
            case 'prp.sens.quatre-factorielle.com':
                return '62e0de54a499748c0e6cd3a4'
            case 'sens.fourdata.io':
                return '63215f40e47b54ce3c28f1fc';
            case 'sens.bee2beep.com': // A remplacer par l'id de l'appli Bee2beep si on la crée
                return '63215f40e47b54ce3c28f1fc';
            case 'client.alorem.net': // A remplacer par l'id de l'appli Alorem si on la crée
                return '63215f40e47b54ce3c28f1fc'
            case 'client.oleo100.com':
                return '638606c7e47b54ce3c28f5b1'
            case 'sens.fuel-it.io':
                return '638f1b217446e48a2a0e8af3'
            case 'sens.data-waste.io':
                return '639857e18f3b62fb34b2939a'
            case 'vision-jauges.picoty.fr':
                return '64e74d4026fd5b0367fa5225'
        }

        UserRepository.platformDoesNotExists(window.location.hostname)
    }

    retrievePlatform() {
        switch (window.location.hostname) {
            case 'prp.sens.quatre-factorielle.com':
                return 'empty-shell'
            case 'sens.fourdata.io':
                return 'fourdata'
            case 'sens.bee2beep.com':
                return 'fourdata'
            case 'client.alorem.net': // A remplacer par l'id de l'appli Alorem si on la crée
                return 'fourdata'
            case 'client.oleo100.com':
                return 'oleo100'
            case 'sens.fuel-it.io':
                return 'fuel-it'
            case 'sens.data-waste.io':
                return 'data-waste'
            case 'vision-jauges.picoty.fr':
                return 'allium'
        }

        UserRepository.platformDoesNotExists(window.location.hostname)
    }
}

export default {
    install(Vue, options) {
        Vue.pushy = new PushyManager(Vue, options)

        Object.defineProperties(Vue.prototype, {
            $pushy: {
                get: function () {
                    return Vue.pushy
                },
            },
        })
    },
}
